import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/kylesoeltz/git/reconnect-v2/src/components/layouts/post-layout.js";
import { graphql } from "gatsby";
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        authors {
          id
          name
        }
        categories {
          id
          name
        }
      }
    }
    allMdx(
      sort: {fields: [frontmatter___date], order: DESC}
      limit: 6
      filter: {frontmatter: {category: {eq: "pacific-crest-trail" }}}
    ) {
      edges {
        node {
          frontmatter {
            author
            date
            path
            title
            featuredImage
          }
          slug
          timeToRead
          excerpt(truncate: false, pruneLength: 200)
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Figcaption = makeShortcode("Figcaption");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The day after I slept in a kitchen shed we hitch hiked back into the desert. We were picked up by a young couple, Anwar and Claudia, who seemed even happier to be driving us than we were to be getting a ride. They asked all about the PCT- where it went, how long we had been out there, the gear we needed, and more. Anwar shared with us a map of destinations he plans on checking out throughout the country since his recent release from the military. After hearing about our trek through the Anza-Borrego desert, they wanted to hike it that day. They were a couple out to find adventure with no time limit or boundaries holding them back.`}</p>
    <p>{`They dropped us off right where we left the day before. We thanked them for the ride and they thanked us for the inspiration. Anwar and Claudia drove off and we were back on the trail again.`}</p>
    <p><img parentName="p" {...{
        "src": "PSX_20180417_063724-500x375.jpg",
        "alt": "Trekking through the Anza-Borrego desert"
      }}></img></p>
    <Figcaption mdxType="Figcaption">Back to the Anza-Borrego desert.</Figcaption>
    <h2>{`Gradient Ecosystems`}</h2>
    <p>{`That night we setup on a ridgeline over the desert where we watched the sun set behind one mountain range and later rise over another. Over the next couple of days we made the push to Warner Springs, the next town on our list.`}</p>
    <p><img parentName="p" {...{
        "src": "PSX_20180417_064534-500x375.jpg",
        "alt": "Sunset over the desert"
      }}></img></p>
    <Figcaption mdxType="Figcaption">Watching the sun set over the desert.</Figcaption>
    <p>{`The desert slowly became lusher and lusher as we made our way into the San Felipe hills. The barren spaces between the cactuses started to fill up with small plants. The plants became bushes, and the bushes eventually became trees. Before we knew it we were passing in and out of small forests. `}</p>
    <p><img parentName="p" {...{
        "src": "PSX_20180418_191417-500x375.jpg",
        "alt": "Forest near Warner Springs"
      }}></img></p>
    <Figcaption mdxType="Figcaption">Not so desert looking anymore.</Figcaption>
    <p>{`We were coming out of yet another small patch of forest when suddenly we weren't in the desert at all anymore. In front of us laid a wide open field of grass, continuing for miles over rolling hills. It felt like I was in The Sound of Music. In the distance we could see hundreds of cows grazing peacefully. It was crazy to think we had been in a barren, lifeless desert only a day ago. `}</p>
    <p><img parentName="p" {...{
        "src": "PSX_20180418_191756-500x375.jpg",
        "alt": "A lone tree in the field"
      }}></img></p>
    <Figcaption mdxType="Figcaption">A lone tree sitting in one of the vast fields. I _had_ to walk off trail to take a picture of it, both for me and for my girlfriend back home who loves trees.</Figcaption>
    <h2>{`An Unexpected Hiker Oasis`}</h2>
    <p>{`Walking through a patch of forest only a couple miles from Warner Springs, I encountered my biggest trail hazard yet. Standing before me and directly on my path was an intimidatingly large herd of cows.`}</p>
    <p><img parentName="p" {...{
        "src": "PSX_20180418_192232-500x281.jpg",
        "alt": "Cows along the Pacific Crest Trail"
      }}></img></p>
    <Figcaption mdxType="Figcaption">Please moooove out of my way.</Figcaption>
    <p>{`They seemed content to completely ignore me, chomping away at some seemingly delicious grass while I pondered how to get around them. However as soon as I got within 15 feet of them they would all stop and stare directly at me. Being a suburban kid with little-to-no farm experience, I wasn't totally sure what to do when a herd of 20 cows all had their focus on me. Another hiker came up behind me who seemed to have had prior experience with trail cows. He started banging his poles together and the cows slowly trotted away until we could eventually continue to our destination.`}</p>
    <p><img parentName="p" {...{
        "src": "PSX_20180418_192616-500x375.jpg",
        "alt": "Herding cows off the Pacific Crest Trail"
      }}></img></p>
    <Figcaption mdxType="Figcaption">Herding the cows.</Figcaption>
    <p>{`The area of Warner Springs that Dennis and I stayed at was a school turned hiker oasis. A section of their athletic field was set aside for hikers to setup tents in, they had converted classrooms into hiker hangout spaces, and closets were stocked with hiker food and supplies for purchase.`}</p>
    <p><img parentName="p" {...{
        "src": "PSX_20180418_192805-500x375.jpg",
        "alt": "Sunrise at Warner Springs"
      }}></img></p>
    <Figcaption mdxType="Figcaption">Sunrise at Warner Springs.</Figcaption>
    <p>{`It was here that I learned of a 'bucket shower'. It is exactly what it sounds like- you fill a bucket with (cold) water then dump it on yourself to take a shower. Having been denied a shower for nearly a week, if pouring cold gallons of water on myself was the best I could get, I sure was going to take it. `}</p>
    <h2>{`Chain Link Fences, Thoreau, and Water Troughs`}</h2>
    <p>{`The next few days became a hard push to get to the city of Idyllwild. The miles we did each day were dependent on where we could find water as we weaved between forests and cismontane chaparral.`}</p>
    <p><img parentName="p" {...{
        "src": "PSX_20180418_193425-500x375.jpg",
        "alt": "Cloudy day in the desert of the PCT"
      }}></img></p>
    <Figcaption mdxType="Figcaption">Just because it's the desert doesn't mean we don't get any cold and cloudy days. The sun is the only thing providing any heat out there!</Figcaption>
    <p>{`The first night we stayed at Mike's Place. It was this small trailer park/shack, alone in the middle of the mountains, accessible only by a beaten up dirt road. The area was littered with the trash of forgotten dreams. I pitched my tent next to some rusted and decaying workout equipment. I fell asleep to the sound of howling winds smacking against chain link fences guarding nothing.`}</p>
    <p><img parentName="p" {...{
        "src": "PSX_20180418_193716-500x375.jpg",
        "alt": "Decaying bench press"
      }}></img></p>
    <Figcaption mdxType="Figcaption">Real men use splintery half benches to get swol.</Figcaption>
    <p>{`Another night we stayed at Muir Ranch, also known as Mary's Water Tank. It was a quaint little spot with a mini free library and wooden cutouts of famous outdoorsmen, such as John Muir and Thoreau. On another night we drank out of a water trough fed from an underground spring.`}</p>
    <p><img parentName="p" {...{
        "src": "PSX_20180418_204807-500x375.jpg",
        "alt": "A water trough on the Pacific Crest Trail"
      }}></img></p>
    <Figcaption mdxType="Figcaption">Delicious. (It was actually very fresh and clean tasting!)</Figcaption>
    <h2>{`The Fire Closure`}</h2>
    <p>{`Right before heading into Idyllwild there was a 10 mile section of the trail that was closed due to forest fire damage. Leading up to the closure was one of the steepest sections we had the entire trip- 1200 feet of elevation gained in only 2 miles. It was also one of my favorite sections of the trail, as the ecosystem became that out of a thick forest that reminded me of the woods back home in New England.`}</p>
    <p><img parentName="p" {...{
        "src": "PSX_20180418_200134-500x375.jpg",
        "alt": "A giant pine cone"
      }}></img></p>
    <Figcaption mdxType="Figcaption">The pine trees have GIANT pine cones. Some can get up to 5lbs in weight. I carried one out as a gift for my girlfriend.</Figcaption>
    <p>{`Sadly the scars of the fire started to show through as the brilliant trees were slowly replaced with the charred husks of vegetation past. We continued our hard push upward, sweating and breathing heavily the whole way. Our reward? A brief view of the Salton Sea followed by going all the way back down and then some.`}</p>
    <p><img parentName="p" {...{
        "src": "PSX_20180418_200552-500x375.jpg",
        "alt": "Fire scarred forests"
      }}></img></p>
    <Figcaption mdxType="Figcaption">Imagine how lush this would have been were it not for the forest fire. I believe this one was actually started by someone who didn't fully put out their camp fire (some are started naturally, such as from lightning).</Figcaption>
    <p>{`We took an alternate route around the closure into Idyllwild where we ended up resting and taking some time off for a day and a half. Dennis even met the mayor! I was a little jealous, seeing as the mayor is a golden retriever wearing a tie (he was officially elected into office).`}</p>
    <p><img parentName="p" {...{
        "src": "PSX_20180418_201155-500x490.jpg",
        "alt": "Mayor Max of Idyllwild, CA"
      }}></img></p>
    <Figcaption mdxType="Figcaption">[I'm not joking.](https://mayormax.com/) Photo taken by Dennis Anderson.</Figcaption>
    <p>{`Idyllwild was a cool little town, on the weekend it was buzzing with people going in and out of coffee shops, restaurants, and trinket stores. It felt like we could have been somewhere in Boston only in a forest. But then as soon as the weekend ended, the place felt like a ghost town. All the tourists had gone and all the shops had closed. We grabbed some good food and made some gear upgrades, all the while Mount San Jacinto (and it's over 6000 feet of elevation gain) loomed over the town, ready for us to take it on following our time off. `}</p>
    <p><img parentName="p" {...{
        "src": "PSX_20180418_202301-500x375.jpg",
        "alt": "Mount San Jacinto"
      }}></img></p>
    <Figcaption mdxType="Figcaption">Mount San Jacinto stands at over 10,880 feet above sea level, and from the top you look straight down at the desert floor at 170 feet above sea level.</Figcaption>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      